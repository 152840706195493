// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-formik-page-js": () => import("./../../../src/pages/formik-page.js" /* webpackChunkName: "component---src-pages-formik-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-js": () => import("./../../../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-planes-js": () => import("./../../../src/pages/planes.js" /* webpackChunkName: "component---src-pages-planes-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-registrate-js": () => import("./../../../src/pages/registrate.js" /* webpackChunkName: "component---src-pages-registrate-js" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

